import React, { useEffect, useState } from "react";

import { Grid, Box, Typography, Button } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import DescriptionIcon from "@mui/icons-material/Description";

import TabView from "../../components/Tabs/Tabs";

import { getEmploymentHistory, getApplicationData, getExperience, getAccident, getTrafficViolation, getAdverseLicensing, getSign } from "../../redux/thunks/ApplicantLogin-thunk";
import useAuth from "../../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Edit } from "@mui/icons-material";
import EditOffIcon from "@mui/icons-material/EditOff";
import HistoryIcon from '@mui/icons-material/History';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import TrafficIcon from '@mui/icons-material/Traffic';
import CarCrashIcon from '@mui/icons-material/CarCrash';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import Logo from "../../assets/images/loader.gif";
import EmploymentHistoryForm from "./EmploymentHistoryForm";
import ExperienceForm from "./ExperienceForm";
import AccidentHistoryForm from "./AccidentHistoryForm";
import TrafficViolation from "./TrafficViolation";
import AdverseLicense from "./AdverseLicense";
import Signed from "./Signed";
import { ApplicantLoginActions } from '../../redux/reducers/ApplicantLogin-Slice'

function DriveRApplication(props) {
  const dispatch = useDispatch();
  const { auth } = useAuth();

  const location = useLocation();

  const [disablePersonalForm, setDisablePersonalForm] = useState(
    auth.role[0] === "A" ? false : true
  );




  const employementHistory = useSelector((state) => state.ApplicantLogin.employementHistory);

  const experienceData = useSelector((state) => state.ApplicantLogin.experience);

  const accidentData = useSelector((state) => state.ApplicantLogin.accidentHistory);

  const trafficViolationData = useSelector((state) => state.ApplicantLogin.trafficViolation);

  const adverseLicensingData = useSelector((state) => state.ApplicantLogin.adverseLicensing);

  const readAndSignData = useSelector((state) => state.ApplicantLogin.readAndSign);


  const applicationData = useSelector((state) => state.ApplicantLogin.applicationData);

  const companyLogo = useSelector((state) => state.ThemeSlice?.companyLogo);




  // --------------------------------------------------employment history initial value-----------------------------------------

  const initValuesEmploymentHistory = {
    employment_history: [
      {
        applicant: location?.state?.user,
        employer_name: "",
        employer_email: "",
        phone: "",
        line_1: "",
        line_2: "",
        country: "",
        state: "",
        city: "",
        zipcode: "",
        employed_from: "",
        employed_to: "",
        position_held: "",
        reason_for_leaving: "",
        additional_information: {
          question_1: "Were you subject to FMCSRs while employed?",
          answer_1: "",
          question_2: "Was your job designated as a safety-sensitive function in any DOT-regulated mode subject to the drug and alcohol testing requirements of 49 CFR Part 40?",
          answer_2: "",
          question_3: "Account for Remaining Period of Time Required for Employement History (Gap of Employement)",
          answer_3: "",
          answer_3_from: "",
          answer_3_to: "",
          employment_status: "",
        },
      },
    ],
  };


  const experience = {
    applicant: location?.state?.user,
    details: {
      equipment_experience_bool: true,
      work_previous: "",
      other_work_previous: "",
      cmvd_since: "",
      cmve: "",
      sb_eqp: [

      ]
    },
    equipment_experience: [

    ]
  }


  const accident = {
    applicant: location?.state?.user,
    details: {
      accident_history: true
    },
    accidents: [
      {
        accident_date: "",
        nature: "",
        country: "",
        state: "",
        city: "",
        no_of_fatalities: "",
        no_of_injuries: "",
        hazmat_spill: "No"
      }
    ]
  }



  const initValueTrafficViolation = {
    applicant: location?.state?.user,
    details: "",
    convictions: [
      {
        country: "",
        state: "",
        date: "",
        charge: "",
        penalty: ""
      }
    ]
  }



  const initValueAdverseLicense = {
    applicant: location?.state?.user,
    details: {
      adverse_lic_actions: "No",
      a_year: "",
      a_reason: "",
      lic_suspended: "No",
      s_year: "",
      s_reason: ""
    }
  }

  console.log(location)

  const initValueSign = {
    applicant: location?.state?.user,
    full_name: location?.state?.name,
    ssn: '',
    accept: false,
    date: '',
    hiring_location: '',
    signature: ''
  }







  // -----------------------------------------------call the data for pre fiiled------------------------------------------------------------



  const [initEmploymentHistory, setInitEmploymentHistory] = useState(initValuesEmploymentHistory);
  const [TrafficViolationInit, setTrafficViolation] = useState(initValueTrafficViolation);
  const [initExperience, setInitExperience] = useState(experience)
  const [initAccident, setInitAccident] = useState(accident)
  const [initAdverseLicense, setinitAdverseLicense] = useState(initValueAdverseLicense)
  const [initSign, setinitSign] = useState(initValueSign)


  useEffect(() => {
    return () => {
      dispatch(ApplicantLoginActions.employementHistory(''))
      dispatch(ApplicantLoginActions.experience(''))
      dispatch(ApplicantLoginActions.accidentHistory(''))
      dispatch(ApplicantLoginActions.trafficViolation(''))
      dispatch(ApplicantLoginActions.adverseLicensing(''))
      dispatch(ApplicantLoginActions.readAndSign(''))
    }
  }, [])


  const getAllData = () => {
    if (location?.state?.user) {
      dispatch(getEmploymentHistory(location?.state?.user));
      dispatch(getExperience(location?.state?.user));
      dispatch(getAccident(location?.state?.user));
      dispatch(getTrafficViolation(location?.state?.user));
      dispatch(getAdverseLicensing(location?.state?.user));
      dispatch(getSign(location?.state?.user));
    } else {
      if (auth.role[0] === "A") dispatch(getApplicationData("Applicant"))
    }
  }


  useEffect(() => {
    getAllData()
  }, []);



  useEffect(() => {
    if (employementHistory.length) {
      setInitEmploymentHistory({ employment_history: [...employementHistory] })
    }
  }, [employementHistory]);

  useEffect(() => {
    if (experienceData.applicant) {
      setInitExperience(experienceData)
    }
  }, [experienceData]);

  useEffect(() => {
    if (accidentData.applicant) {
      setInitAccident(accidentData)
    }
  }, [accidentData]);


  useEffect(() => {
    if (applicationData?.id) {
      dispatch(getEmploymentHistory(applicationData?.id));
      dispatch(getExperience(applicationData?.id));
      dispatch(getAccident(applicationData?.id));
      dispatch(getTrafficViolation(applicationData?.id));
      dispatch(getAdverseLicensing(applicationData?.id));
      dispatch(getSign(applicationData?.id));
    }
  }, [applicationData])


  useEffect(() => {
    if (trafficViolationData.applicant) {
      setTrafficViolation(trafficViolationData)
    }
  }, [trafficViolationData]);

  useEffect(() => {
    if (adverseLicensingData.applicant) {
      setinitAdverseLicense(adverseLicensingData)
    }
  }, [adverseLicensingData]);

  useEffect(() => {
    if (readAndSignData.applicant) {
      setinitSign(readAndSignData)
    }
  }, [readAndSignData]);












  const TabProps = {
    tab_details: [
      {
        icon: (
          <HistoryIcon
            sx={{
              marginRight: "0.5rem",
              fontSize: { xs: "1.2rem", sm: "1.5rem", md: "1.7rem" },
            }}
          />
        ),
        text: "Employment History",
      },
      {
        icon: (
          <HomeRepairServiceIcon
            sx={{
              marginRight: "0.5rem",
              fontSize: { xs: "1.2rem", sm: "1.5rem", md: "1.7rem" },
            }}
          />
        ),
        text: "Experience",
      },
      {
        icon: (
          <CarCrashIcon
            sx={{
              marginRight: "0.5rem",
              fontSize: { xs: "1.2rem", sm: "1.5rem", md: "1.7rem" },
            }}
          />
        ),
        text: "Accident History",
      },
      {
        icon: (
          <TrafficIcon
            sx={{
              marginRight: "0.5rem",
              fontSize: { xs: "1.2rem", sm: "1.5rem", md: "1.7rem" },
            }}
          />
        ),
        text: "Traffic Violation",
      },

      {
        icon: (
          <DocumentScannerIcon
            sx={{
              marginRight: "0.5rem",
              fontSize: { xs: "1.2rem", sm: "1.5rem", md: "1.7rem" },
            }}
          />
        ),
        text: "Adverse Licensing Actions",
      },
      {
        icon: (
          <HistoryEduIcon
            sx={{
              marginRight: "0.5rem",
              fontSize: { xs: "1.2rem", sm: "1.5rem", md: "1.7rem" },
            }}
          />
        ),
        text: "Consent",
      },
    ],
    children: [
      <EmploymentHistoryForm
        auth={auth}
        initValue={initEmploymentHistory}
        disabled={disablePersonalForm}
      />,
      <ExperienceForm
        auth={auth}
        initValue={initExperience}
        disabled={disablePersonalForm}
      />,
      <AccidentHistoryForm
        auth={auth}
        initValue={initAccident}
        disabled={disablePersonalForm}
      />,

      <TrafficViolation
        auth={auth}
        initValue={TrafficViolationInit}
        disabled={disablePersonalForm}
      />,
      <AdverseLicense
        auth={auth}
        initValue={initAdverseLicense}
        disabled={disablePersonalForm}
      />,
      <Signed
        auth={auth}
        initValue={initSign}
        disabled={disablePersonalForm} />
    ],
    sm: '0.8rem',
    md: '0.8rem'
  };

  return (
    <Box sx={{ padding: "0 1rem 1rem 1rem" }} className='driverApplicationMainDiv'>
      {auth.role[0] === "A" ? (
        <Box
          component="img"
          src={companyLogo ? companyLogo : Logo}
          sx={{
            padding: "10px 5px",
            width: !companyLogo ? "60px" : "100px",
          }}
        />
      ) : null}

      <Box
        sx={{
          background: "rgba( 255, 255, 255, 0.1 )",

          boxShadow: "0 5px 32px 5px #e2e4f8",

          backdropFilter: "blur( 20px )",
          webkitBackdropFilter: "blur( 20px )",
          borderRadius: "10px",
          border: "1px solid rgba( 255, 255, 255, 0.18 )",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: "1rem",
            paddingTop: "20px",
          }}
        >
          <Typography
            component={"p"}
            variant={"h6"}
            sx={{
              marginRight: { xs: "0", md: "3rem" },
              marginLeft: { xs: "1rem", md: "3rem" },
              fontSize: { xs: "1rem", md: "1.5rem" },
            }}
          >
            Fill the forms to continue
          </Typography>
          {disablePersonalForm ? (
            <Button
              endIcon={<Edit />}
              variant={"contained"}
              onClick={() => setDisablePersonalForm(false)}
              sx={{
                background:
                  "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)",
                // width: "100%",
                borderRadius: "20px",
                color: "#fff",
                height: "2.6em",
                marginLeft: "6px",
                fontSize: {
                  xs: "0.8rem",
                  md: "1rem",
                },
              }}
            >
              Edit
            </Button>
          ) : auth.role[0] !== "A" ? (
            <>
              <Button
                endIcon={<EditOffIcon />}
                variant={"contained"}
                onClick={() => setDisablePersonalForm(true)}
                sx={{
                  background:
                    "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)",
                  // width: "100%",
                  borderRadius: "20px",
                  color: "#fff",
                  height: "2.6em",
                  display: { xs: "none", md: "flex" },
                  marginLeft: "10px",
                  fontSize: {
                    xs: "0.6rem",
                    md: "1rem",
                  },
                }}
              >
                Cancel Edit
              </Button>
              <EditOffIcon
                sx={{ marginLeft: "20px", display: { xs: "flex", md: "none" } }}
                onClick={() => setDisablePersonalForm(true)}
              />
            </>
          ) : null}
        </Box>

        <TabView tabProps={TabProps} />
      </Box>
    </Box>
  );
}

export default DriveRApplication;
