import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { AttachMoney, Campaign, Group, Logout } from "@mui/icons-material";
import BadgeIcon from '@mui/icons-material/Badge';
import PersonIcon from "@mui/icons-material/Person";
import styled from "@emotion/styled";
import useAuth from "../../hooks/useAuth";
import classes from "./sideBar.module.css";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
// import DashboardIcon from "../../assets/icons/dashboard-icon.svg";

function Sidebar({ mode, setMode }) {
  const [activeSidebarButton, setActiveSidebarButton] = useState(
    window.location.href.split("/")[3] !== "application"
      ? window.location.href.split("/")[3]
      : "manageapplicants"
  );
  const [imgLoaded, setImgLoaded] = useState(false)

  const companyLogo = useSelector((state) => state.ThemeSlice?.companyLogo);


  useEffect(() => {
    if (companyLogo) setImgLoaded(true)
  }, [companyLogo])

  const { logoutUser, auth } = useAuth();
  const SideBarItems = styled(Box)(({ theme }) => ({
    "&:hover": {
      // borderLeft: `4px solid ${theme.palette.primary.button}`,
      // background: "#fff",
      color: "#fff",
      "& .nav_icon": {
        fill: theme.palette.primary.button,
      },
      "& .nav_text": {
        color: theme.palette.primary.button,
      },
    },

    width: "100%",
    textTransform: "capitalize",
    transition: "all 0.3s",
    textDecoration: "none",
  }));

  const SideBarItem = styled(Box)(({ theme }) => ({
    background: "#fff",
    display: "flex",
    padding: "12px 15px",
  }));

  const handleSidebar = (name) => {
    setActiveSidebarButton(name);
  };

  const SideBarPropsAdmin = [
    {
      icon: <DashboardIcon className="nav_icon" />,
      text: "Dashboard",
      textUse: "dashboard",
    },

    {
      icon: <ApartmentIcon className="nav_icon" />,
      text: "Company Profile",
      textUse: "companyprofile",
    },
    {
      icon: <Group className="nav_icon" />,
      text: "Manage Applicants",
      textUse: "manageapplicants",
    },
    {
      icon: <ManageAccountsIcon className="nav_icon" />,
      text: "Manage Users",
      textUse: "manageuser",
    },
    {
      icon: <BadgeIcon className="nav_icon" />,
      text: "Current Employees",
      textUse: "currentemployees",
    },
  ];

  const SideBarPropsHR = [
    {
      icon: <DashboardIcon className="nav_icon" />,
      text: "Dashboard",
      textUse: "dashboard",
    },

    {
      icon: <PersonIcon className="nav_icon" />,
      text: " User Profile",
      textUse: "userprofile",
    },
    {
      icon: <Group className="nav_icon" />,
      text: "Manage Applicants",
      textUse: "manageapplicants",
    },
    {
      icon: <BadgeIcon className="nav_icon" />,
      text: "Current Employees",
      textUse: "currentemployees",
    },
  ];

  let SideBarProps = auth.role[0] === "CA" ? SideBarPropsAdmin : SideBarPropsHR;

  return (
    <>
      <Box
        sx={{
          width: "200px",
          height: "200px",
          margin: "0 auto",
          display: "flex",
          justifyContent: "center",
          alignItems: 'center'
        }}
      >
        {
          !imgLoaded ?
            <Typography>Loading...</Typography>
            :
            <Box
              component="img"
              src={companyLogo}
              sx={{
                padding: "20px",
                width: "200px",
                maxHeight: "200px",
              }}
            />
        }


      </Box>

      <Divider variant="middle" />
      <List>
        {SideBarProps.map((item, index) => (
          <Link
            to={`/${item.textUse}`}
            style={{ textDecoration: "none" }}
            key={index}
          >
            <SideBarItems>
              <SideBarItem
                // name="dashboard"
                className={
                  activeSidebarButton === item.textUse
                    ? classes.active_sidebar_nav +
                    " " +
                    classes.active_nav_icon +
                    " " +
                    classes.active_nav_text
                    : classes.sidebar_nav +
                    " " +
                    classes.nav_text +
                    " " +
                    classes.nav_icon
                }
                // className={classes.active_sidebar_nav}
                onClick={() => handleSidebar(item.textUse)}
              // value="dashboard"
              >
                <Box sx={{ minWidth: "40px", display: "flex" }}>
                  {item.icon}
                </Box>
                <ListItemText className="nav_text" primary={item.text} />
              </SideBarItem>
            </SideBarItems>
          </Link>
        ))}


        <SideBarItems>
          <SideBarItem
            // name="dashboard"
            className={
              activeSidebarButton === "login"
                ? classes.active_sidebar_nav +
                " " +
                classes.active_nav_icon +
                " " +
                classes.active_nav_text
                : classes.sidebar_nav +
                " " +
                classes.nav_text +
                " " +
                classes.nav_icon
            }
            // className={classes.active_sidebar_nav}
            onClick={() => logoutUser()}
          // value="dashboard"
          >
            <Box sx={{ minWidth: "40px", display: "flex" }}>
              <Logout className="nav_icon" />
            </Box>
            <ListItemText className="nav_text" primary={"Logout"} />
          </SideBarItem>
        </SideBarItems>
      </List>
    </>
  );
}

export default Sidebar;
